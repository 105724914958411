@use  "variables" as vars;
@use  "custom";

// Tailwind imports
@use  "tailwindcss/base";
@use  "tailwindcss/components";
@use  "tailwindcss/utilities";

:root {
  --base-white: #{vars.$base-white};

  --gray-900: #{vars.$gray-900};
  --gray-800: #{vars.$gray-800};
  --gray-700: #{vars.$gray-700};
  --gray-600: #{vars.$gray-600};
  --gray-500: #{vars.$gray-500};
  --gray-400: #{vars.$gray-400};
  --gray-200: #{vars.$gray-50};
  --gray-100: #{vars.$gray-100};
  --gray-50: #{vars.$gray-50};

  --gray-true-900: #{vars.$gray-true-900};
  --gray-true-800: #{vars.$gray-true-800};
  --gray-true-600: #{vars.$gray-true-600};

  --lightslategray: #{vars.$lightslategray};

  --gray1-100: #{vars.$gray1-100};
  --gray1-200: #{vars.$gray1-200};
  --dimgray: #{vars.$dimgray};

  --error-500: #{vars.$error-500};
  --error-300: #{vars.$error-300};

  --brand-700: #{vars.$brand-700};
  --brand-600: #{vars.$brand-600};
  --brand-500: #{vars.$brand-500};
  --brand-300: #{vars.$brand-300};
  --brand-200: #{vars.$brand-200};
  --brand-50: #{vars.$brand-50};

  --success-700: #{vars.$success-700};
  --success-200: #{vars.$success-200};
  --success-50: #{vars.$success-50};

  --gainsboro-200: #{vars.$gainsboro-200};
  --gainsboro-100: #{vars.$gainsboro-100};

  --mediumaquamarine: #{vars.$mediumaquamarine};

  --error-700: #{vars.$error-700};
  --error-200: #{vars.$error-200};
  --error-50: #{vars.$error-50};

  --pink: #{vars.$pink};
  --blue-700: #{vars.$blue-700};
}
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
