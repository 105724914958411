body,
html {
  overflow-x: hidden;
  max-width: 100%;
}
// .testClass {
//   background-color: $gray-50;
//   height: 100px;
//   width: 100px;
// }

.modal-backdrop-color {
  background-color: darkgrey;
  opacity: 0.9;
}
.stripe-element {
  border: 1px solid rgb(209 213 219) !important;
  border-radius: 0.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  border-width: 0px;
}
